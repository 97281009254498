import { default as index73jZT011rUMeta } from "/codebuild/output/src1861962184/src/pages/[slug]/index.vue?macro=true";
import { default as accept_45inviteAoHMX5M376Meta } from "/codebuild/output/src1861962184/src/pages/accept-invite.vue?macro=true";
import { default as extra_45purchaseolsiMFy9A7Meta } from "/codebuild/output/src1861962184/src/pages/account/extra-purchase.vue?macro=true";
import { default as index9np44KYzLuMeta } from "/codebuild/output/src1861962184/src/pages/account/index.vue?macro=true";
import { default as settings4dCXYGX6JDMeta } from "/codebuild/output/src1861962184/src/pages/account/settings.vue?macro=true";
import { default as subscriptionCEvyYRByhaMeta } from "/codebuild/output/src1861962184/src/pages/account/subscription.vue?macro=true";
import { default as transactionsJQbg6DhDZ6Meta } from "/codebuild/output/src1861962184/src/pages/account/transactions.vue?macro=true";
import { default as update_45subscriptionP4Lic8IUoRMeta } from "/codebuild/output/src1861962184/src/pages/account/update-subscription.vue?macro=true";
import { default as previewjSlKGtseH6Meta } from "/codebuild/output/src1861962184/src/pages/admin/groups/[slug]/emails/[id]/preview.vue?macro=true";
import { default as composeIXbRTxHmxdMeta } from "/codebuild/output/src1861962184/src/pages/admin/groups/[slug]/emails/compose.vue?macro=true";
import { default as indexg6ToqG7oHeMeta } from "/codebuild/output/src1861962184/src/pages/admin/groups/[slug]/emails/index.vue?macro=true";
import { default as emailsUpRKKAT8RkMeta } from "/codebuild/output/src1861962184/src/pages/admin/groups/[slug]/emails.vue?macro=true";
import { default as editj0NkoyWPURMeta } from "/codebuild/output/src1861962184/src/pages/admin/groups/[slug]/events/[id]/edit.vue?macro=true";
import { default as registrations5uvA5hlmjVMeta } from "/codebuild/output/src1861962184/src/pages/admin/groups/[slug]/events/[id]/registrations.vue?macro=true";
import { default as indexAx1b6987QrMeta } from "/codebuild/output/src1861962184/src/pages/admin/groups/[slug]/events/index.vue?macro=true";
import { default as eventsDKBbJeo411Meta } from "/codebuild/output/src1861962184/src/pages/admin/groups/[slug]/events.vue?macro=true";
import { default as filesjIWoS5sNGeMeta } from "/codebuild/output/src1861962184/src/pages/admin/groups/[slug]/files.vue?macro=true";
import { default as galleryZFfzQV0j5UMeta } from "/codebuild/output/src1861962184/src/pages/admin/groups/[slug]/gallery.vue?macro=true";
import { default as group_45details1Fri8a8IMpMeta } from "/codebuild/output/src1861962184/src/pages/admin/groups/[slug]/group-details.vue?macro=true";
import { default as index9awjEWRvdnMeta } from "/codebuild/output/src1861962184/src/pages/admin/groups/[slug]/index.vue?macro=true";
import { default as membersKzDXCPsaANMeta } from "/codebuild/output/src1861962184/src/pages/admin/groups/[slug]/members.vue?macro=true";
import { default as editg8BTFenlE4Meta } from "/codebuild/output/src1861962184/src/pages/admin/groups/[slug]/news/[id]/edit.vue?macro=true";
import { default as index3qlftjWO9YMeta } from "/codebuild/output/src1861962184/src/pages/admin/groups/[slug]/news/index.vue?macro=true";
import { default as newszNMwAY1KAwMeta } from "/codebuild/output/src1861962184/src/pages/admin/groups/[slug]/news.vue?macro=true";
import { default as _91slug_93jsAkRu26X7Meta } from "/codebuild/output/src1861962184/src/pages/donate/[slug].vue?macro=true";
import { default as indexoAg7jsO75OMeta } from "/codebuild/output/src1861962184/src/pages/donate/index.vue?macro=true";
import { default as _91slug_93YTTLx9TNLQMeta } from "/codebuild/output/src1861962184/src/pages/events/[slug].vue?macro=true";
import { default as indexYYILHOfbYwMeta } from "/codebuild/output/src1861962184/src/pages/events/index.vue?macro=true";
import { default as forgot_45passwordgorZMryO03Meta } from "/codebuild/output/src1861962184/src/pages/forgot-password.vue?macro=true";
import { default as fileslptSn53TKgMeta } from "/codebuild/output/src1861962184/src/pages/groups/[slug]/files.vue?macro=true";
import { default as gallery6MgyKgQ5iNMeta } from "/codebuild/output/src1861962184/src/pages/groups/[slug]/gallery.vue?macro=true";
import { default as indexurLppxlALCMeta } from "/codebuild/output/src1861962184/src/pages/groups/[slug]/index.vue?macro=true";
import { default as shopdYLBNi01H4Meta } from "/codebuild/output/src1861962184/src/pages/groups/[slug]/shop.vue?macro=true";
import { default as _91slug_93SbJy1FkjKtMeta } from "/codebuild/output/src1861962184/src/pages/groups/[slug].vue?macro=true";
import { default as indexcaRTLilMG7Meta } from "/codebuild/output/src1861962184/src/pages/groups/index.vue?macro=true";
import { default as index2rsq3ktxHGMeta } from "/codebuild/output/src1861962184/src/pages/index.vue?macro=true";
import { default as joinrga6NxPcgZMeta } from "/codebuild/output/src1861962184/src/pages/join.vue?macro=true";
import { default as login681agF2gGeMeta } from "/codebuild/output/src1861962184/src/pages/login.vue?macro=true";
import { default as confirmationEPXSwdfBlwMeta } from "/codebuild/output/src1861962184/src/pages/membership/confirmation.vue?macro=true";
import { default as indexy27ymJtABvMeta } from "/codebuild/output/src1861962184/src/pages/membership/index.vue?macro=true";
import { default as _91slug_93WtIDGt5Ly0Meta } from "/codebuild/output/src1861962184/src/pages/news/[slug].vue?macro=true";
import { default as indexgPU0AgzPZMMeta } from "/codebuild/output/src1861962184/src/pages/news/index.vue?macro=true";
import { default as reset_45passworda6nMg28OWvMeta } from "/codebuild/output/src1861962184/src/pages/reset-password.vue?macro=true";
import { default as setup_452faqxHNkWcFeBMeta } from "/codebuild/output/src1861962184/src/pages/setup-2fa.vue?macro=true";
import { default as shopQVCk1KYZzBMeta } from "/codebuild/output/src1861962184/src/pages/shop.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/codebuild/output/src1861962184/src/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "accept-invite",
    path: "/accept-invite",
    component: () => import("/codebuild/output/src1861962184/src/pages/accept-invite.vue").then(m => m.default || m)
  },
  {
    name: "account-extra-purchase",
    path: "/account/extra-purchase",
    meta: extra_45purchaseolsiMFy9A7Meta || {},
    component: () => import("/codebuild/output/src1861962184/src/pages/account/extra-purchase.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/account",
    meta: index9np44KYzLuMeta || {},
    component: () => import("/codebuild/output/src1861962184/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-settings",
    path: "/account/settings",
    meta: settings4dCXYGX6JDMeta || {},
    component: () => import("/codebuild/output/src1861962184/src/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: "account-subscription",
    path: "/account/subscription",
    meta: subscriptionCEvyYRByhaMeta || {},
    component: () => import("/codebuild/output/src1861962184/src/pages/account/subscription.vue").then(m => m.default || m)
  },
  {
    name: "account-transactions",
    path: "/account/transactions",
    meta: transactionsJQbg6DhDZ6Meta || {},
    component: () => import("/codebuild/output/src1861962184/src/pages/account/transactions.vue").then(m => m.default || m)
  },
  {
    name: "account-update-subscription",
    path: "/account/update-subscription",
    meta: update_45subscriptionP4Lic8IUoRMeta || {},
    component: () => import("/codebuild/output/src1861962184/src/pages/account/update-subscription.vue").then(m => m.default || m)
  },
  {
    name: emailsUpRKKAT8RkMeta?.name,
    path: "/admin/groups/:slug()/emails",
    meta: emailsUpRKKAT8RkMeta || {},
    component: () => import("/codebuild/output/src1861962184/src/pages/admin/groups/[slug]/emails.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-groups-slug-emails-id-preview",
    path: ":id()/preview",
    meta: previewjSlKGtseH6Meta || {},
    component: () => import("/codebuild/output/src1861962184/src/pages/admin/groups/[slug]/emails/[id]/preview.vue").then(m => m.default || m)
  },
  {
    name: "admin-groups-slug-emails-compose",
    path: "compose",
    meta: composeIXbRTxHmxdMeta || {},
    component: () => import("/codebuild/output/src1861962184/src/pages/admin/groups/[slug]/emails/compose.vue").then(m => m.default || m)
  },
  {
    name: "admin-groups-slug-emails",
    path: "",
    meta: indexg6ToqG7oHeMeta || {},
    component: () => import("/codebuild/output/src1861962184/src/pages/admin/groups/[slug]/emails/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: eventsDKBbJeo411Meta?.name,
    path: "/admin/groups/:slug()/events",
    meta: eventsDKBbJeo411Meta || {},
    component: () => import("/codebuild/output/src1861962184/src/pages/admin/groups/[slug]/events.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-groups-slug-events-id-edit",
    path: ":id()/edit",
    meta: editj0NkoyWPURMeta || {},
    component: () => import("/codebuild/output/src1861962184/src/pages/admin/groups/[slug]/events/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-groups-slug-events-id-registrations",
    path: ":id()/registrations",
    meta: registrations5uvA5hlmjVMeta || {},
    component: () => import("/codebuild/output/src1861962184/src/pages/admin/groups/[slug]/events/[id]/registrations.vue").then(m => m.default || m)
  },
  {
    name: "admin-groups-slug-events",
    path: "",
    meta: indexAx1b6987QrMeta || {},
    component: () => import("/codebuild/output/src1861962184/src/pages/admin/groups/[slug]/events/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "admin-groups-slug-files",
    path: "/admin/groups/:slug()/files",
    meta: filesjIWoS5sNGeMeta || {},
    component: () => import("/codebuild/output/src1861962184/src/pages/admin/groups/[slug]/files.vue").then(m => m.default || m)
  },
  {
    name: "admin-groups-slug-gallery",
    path: "/admin/groups/:slug()/gallery",
    meta: galleryZFfzQV0j5UMeta || {},
    component: () => import("/codebuild/output/src1861962184/src/pages/admin/groups/[slug]/gallery.vue").then(m => m.default || m)
  },
  {
    name: "admin-groups-slug-group-details",
    path: "/admin/groups/:slug()/group-details",
    meta: group_45details1Fri8a8IMpMeta || {},
    component: () => import("/codebuild/output/src1861962184/src/pages/admin/groups/[slug]/group-details.vue").then(m => m.default || m)
  },
  {
    name: "admin-groups-slug",
    path: "/admin/groups/:slug()",
    meta: index9awjEWRvdnMeta || {},
    component: () => import("/codebuild/output/src1861962184/src/pages/admin/groups/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-groups-slug-members",
    path: "/admin/groups/:slug()/members",
    meta: membersKzDXCPsaANMeta || {},
    component: () => import("/codebuild/output/src1861962184/src/pages/admin/groups/[slug]/members.vue").then(m => m.default || m)
  },
  {
    name: newszNMwAY1KAwMeta?.name,
    path: "/admin/groups/:slug()/news",
    meta: newszNMwAY1KAwMeta || {},
    component: () => import("/codebuild/output/src1861962184/src/pages/admin/groups/[slug]/news.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-groups-slug-news-id-edit",
    path: ":id()/edit",
    meta: editg8BTFenlE4Meta || {},
    component: () => import("/codebuild/output/src1861962184/src/pages/admin/groups/[slug]/news/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-groups-slug-news",
    path: "",
    meta: index3qlftjWO9YMeta || {},
    component: () => import("/codebuild/output/src1861962184/src/pages/admin/groups/[slug]/news/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "donate-slug",
    path: "/donate/:slug()",
    component: () => import("/codebuild/output/src1861962184/src/pages/donate/[slug].vue").then(m => m.default || m)
  },
  {
    name: "donate",
    path: "/donate",
    component: () => import("/codebuild/output/src1861962184/src/pages/donate/index.vue").then(m => m.default || m)
  },
  {
    name: "events-slug",
    path: "/events/:slug()",
    component: () => import("/codebuild/output/src1861962184/src/pages/events/[slug].vue").then(m => m.default || m)
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/codebuild/output/src1861962184/src/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/codebuild/output/src1861962184/src/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93SbJy1FkjKtMeta?.name,
    path: "/groups/:slug()",
    component: () => import("/codebuild/output/src1861962184/src/pages/groups/[slug].vue").then(m => m.default || m),
    children: [
  {
    name: "groups-slug-files",
    path: "files",
    component: () => import("/codebuild/output/src1861962184/src/pages/groups/[slug]/files.vue").then(m => m.default || m)
  },
  {
    name: "groups-slug-gallery",
    path: "gallery",
    component: () => import("/codebuild/output/src1861962184/src/pages/groups/[slug]/gallery.vue").then(m => m.default || m)
  },
  {
    name: "groups-slug",
    path: "",
    component: () => import("/codebuild/output/src1861962184/src/pages/groups/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "groups-slug-shop",
    path: "shop",
    component: () => import("/codebuild/output/src1861962184/src/pages/groups/[slug]/shop.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "groups",
    path: "/groups",
    component: () => import("/codebuild/output/src1861962184/src/pages/groups/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src1861962184/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "join",
    path: "/join",
    component: () => import("/codebuild/output/src1861962184/src/pages/join.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/codebuild/output/src1861962184/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "membership-confirmation",
    path: "/membership/confirmation",
    component: () => import("/codebuild/output/src1861962184/src/pages/membership/confirmation.vue").then(m => m.default || m)
  },
  {
    name: "membership",
    path: "/membership",
    meta: indexy27ymJtABvMeta || {},
    component: () => import("/codebuild/output/src1861962184/src/pages/membership/index.vue").then(m => m.default || m)
  },
  {
    name: "news-slug",
    path: "/news/:slug()",
    component: () => import("/codebuild/output/src1861962184/src/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/codebuild/output/src1861962184/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "reset-password",
    path: "/reset-password",
    component: () => import("/codebuild/output/src1861962184/src/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "setup-2fa",
    path: "/setup-2fa",
    meta: setup_452faqxHNkWcFeBMeta || {},
    component: () => import("/codebuild/output/src1861962184/src/pages/setup-2fa.vue").then(m => m.default || m)
  },
  {
    name: "shop",
    path: "/shop",
    component: () => import("/codebuild/output/src1861962184/src/pages/shop.vue").then(m => m.default || m)
  }
]